var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#ffffff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave(false, "PREPARE")
          },
          "head-finished": function ($event) {
            return _vm.headSave(true, "FINISHED")
          },
          "head-saveBack": function ($event) {
            return _vm.headSave(true, "CONFIRMED")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain", staticStyle: { padding: "0 12px" } },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "150px",
                  disabled:
                    _vm.formType == "view" ||
                    _vm.dataForm.actStatus == "CONFIRMED",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查编号", prop: "actCode" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  disabled:
                                    _vm.dataForm.isAutomatic ||
                                    _vm.pageDisabled ||
                                    _vm.dataForm.id,
                                  placeholder: "请输入检查编号",
                                },
                                model: {
                                  value: _vm.dataForm.actCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "actCode", $$v)
                                  },
                                  expression: "dataForm.actCode",
                                },
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "append" },
                                  [
                                    _vm._v(
                                      "\n                  自动生成\n                  "
                                    ),
                                    _c("el-switch", {
                                      attrs: {
                                        disabled:
                                          _vm.pageDisabled || _vm.dataForm.id,
                                        "active-color": "#13ce66",
                                      },
                                      on: { change: _vm.handleSwitch },
                                      model: {
                                        value: _vm.dataForm.isAutomatic,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataForm,
                                            "isAutomatic",
                                            $$v
                                          )
                                        },
                                        expression: "dataForm.isAutomatic",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查名称", prop: "actName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入检查名称",
                                title: _vm.dataForm.actName,
                              },
                              model: {
                                value: _vm.dataForm.actName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "actName", $$v)
                                },
                                expression: "dataForm.actName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查类型", prop: "checkType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择检查类型",
                                  disabled: "",
                                },
                                model: {
                                  value: _vm.dataForm.checkType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "checkType", $$v)
                                  },
                                  expression: "dataForm.checkType",
                                },
                              },
                              _vm._l(_vm.safey_check_type, function (item) {
                                return _c("el-option", {
                                  key: item.dictKey,
                                  attrs: {
                                    label: item.dictValue,
                                    value: item.dictKey,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "发起人", prop: "startUserName" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请选择发起人" },
                              on: {
                                focus: function ($event) {
                                  return _vm.headAdd("startUserName")
                                },
                              },
                              model: {
                                value: _vm.dataForm.startUserName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "startUserName", $$v)
                                },
                                expression: "dataForm.startUserName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "所属项目",
                              prop: "organizationName",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                title: _vm.dataForm.organizationName,
                                placeholder: "请填写所属项目",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.handleProjectOPen()
                                },
                              },
                              model: {
                                value: _vm.dataForm.organizationName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataForm,
                                    "organizationName",
                                    $$v
                                  )
                                },
                                expression: "dataForm.organizationName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查日期", prop: "checkTime" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "datetime",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                placeholder: "请选择检查日期",
                              },
                              model: {
                                value: _vm.dataForm.checkTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "checkTime", $$v)
                                },
                                expression: "dataForm.checkTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注", prop: "remark" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                autosize: { minRows: 2 },
                                "show-word-limit": "",
                                maxlength: "500",
                                placeholder: "请填写备注",
                              },
                              model: {
                                value: _vm.dataForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "remark", $$v)
                                },
                                expression: "dataForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "shuttleBackBox" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c("el-tab-pane", {
                      attrs: {
                        label: "检查人员",
                        name: "thirdPartyOrganization",
                      },
                    }),
                    _c("el-tab-pane", {
                      attrs: { label: "检查项", name: "corePersonnel" },
                    }),
                    _c("el-tab-pane", {
                      attrs: {
                        label: "相关资料 ",
                        name: "thirdPartyPersonnel",
                      },
                    }),
                    _vm.formType == "view"
                      ? _c("el-tab-pane", {
                          attrs: { label: "问题清单", name: "taskList" },
                        })
                      : _vm._e(),
                    _vm.formType == "view"
                      ? _c("el-tab-pane", {
                          attrs: { label: "整改通知书", name: "Rectify" },
                        })
                      : _vm._e(),
                    _vm.formType == "view"
                      ? _c("el-tab-pane", {
                          attrs: { label: "处罚通知书", name: "punishment" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.activeName == "corePersonnel"
                  ? _c(
                      "el-container",
                      { staticClass: "main-box" },
                      [
                        _c("CommonTree", {
                          ref: "commonTreeCore",
                          attrs: {
                            treeTitle: "检查项分类",
                            isShowdig: _vm.dataForm.actStatus == "PREPARE",
                            "node-key": "id",
                            isLeafIcons: "",
                            defaultExpandedKeys: _vm.defaultExpandedKeys,
                            iconsFlied: "isObj",
                            searchTitle: "typeName",
                            treeData: _vm.corePersonnelTreeData,
                            defaultProps: _vm.defaultProps,
                            showCheckbox: false,
                            treeExpand: true,
                          },
                          on: {
                            getTreeAdd: _vm.getTreeAdd,
                            getTreeDelete: _vm.getTreeDelete,
                            getTreeEdit: _vm.getTreeEdit,
                            getNodeClick: _vm.corePersonnelTreeNodeClick,
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "table-box" },
                          [
                            _vm.formType != "view"
                              ? _c("head-layout", {
                                  attrs: {
                                    "head-btn-options":
                                      _vm.dataForm.actStatus == "PREPARE"
                                        ? _vm.corePersonnelHeadBtnOptions
                                        : [],
                                    "head-title": "检查项",
                                  },
                                  on: {
                                    "head-export": _vm.headExport,
                                    "head-fx": _vm.headFx,
                                    "head-del": _vm.headDel,
                                    handleImport: _vm.handleImport,
                                    "head-add": _vm.addjcx,
                                  },
                                })
                              : _vm._e(),
                            _vm.dataForm.actStatus
                              ? _c("grid-layout", {
                                  ref: "jcxGridLayOut",
                                  attrs: {
                                    "table-options":
                                      _vm.corePersonnelTableOption,
                                    "data-total": _vm.page.total,
                                    page: _vm.page,
                                    "cell-style": _vm.cellStyle,
                                    "table-data": _vm.corePersonnelTableData,
                                    "table-loading": _vm.jcxTableLoading,
                                  },
                                  on: {
                                    "page-current-change": _vm.getJcx,
                                    "page-size-change": _vm.getJcx,
                                    "gird-handle-select-click":
                                      _vm.selectionChange,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "customBtn",
                                        fn: function ({ row }) {
                                          return [
                                            _vm.formType == "view" &&
                                            _vm.dataForm.actStatus == "FINISHED"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      margin: "0 3px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.zxjc(row)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "查看\n                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.formType != "view" &&
                                            _vm.dataForm.actStatus == "PREPARE"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      margin: "0 3px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addjcx(row)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "编辑\n                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.formType != "view" &&
                                            _vm.dataForm.actStatus == "PREPARE"
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      margin: "0 3px",
                                                    },
                                                    attrs: {
                                                      type: "text",
                                                      size: "small",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.rowDel([row])
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "删除\n                "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    491414926
                                  ),
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.activeName == "thirdPartyOrganization"
                  ? _c(
                      "div",
                      [
                        _vm.formType != "view"
                          ? _c("head-layout", {
                              attrs: {
                                "head-title": "检查人员",
                                "head-btn-options":
                                  _vm.dataForm.actStatus == "PREPARE"
                                    ? _vm.userBtnOptions
                                    : [],
                              },
                              on: {
                                "head-add": function ($event) {
                                  return _vm.headAdd1("jcry")
                                },
                                "head-remove": _vm.headRemove,
                              },
                            })
                          : _vm._e(),
                        _c("grid-layout", {
                          ref: "gridLayOut1",
                          attrs: {
                            "table-options":
                              _vm.thirdPartyOrganizationTableOption,
                            "data-total": _vm.jcryPage.total,
                            page: _vm.jcryPage,
                            "table-data": _vm.thirdPartyOrganizationTableData,
                            "table-loading": _vm.jcryTableLoading,
                          },
                          on: {
                            "page-current-change": _vm.getJcUser,
                            "page-size-change": _vm.getJcUser,
                            "gird-handle-select-click": _vm.selectionUserChange,
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm.formType != "view" &&
                              _vm.dataForm.actStatus == "PREPARE"
                                ? {
                                    key: "customBtn",
                                    fn: function ({ row }) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { margin: "0 3px" },
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.rowUserDel([row])
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.activeName == "thirdPartyPersonnel"
                  ? _c(
                      "div",
                      [
                        _vm.formType != "view"
                          ? _c(
                              "head-layout",
                              {
                                attrs: {
                                  "head-title": "资料清单",
                                  "head-btn-options":
                                    _vm.dataForm.actStatus == "PREPARE"
                                      ? _vm.fileBtnOptions
                                      : [],
                                },
                                on: { headImport: _vm.headImport },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "right", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.quote()
                                      },
                                    },
                                    slot: "right",
                                  },
                                  [_vm._v("知识库选择")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("el-upload", {
                          staticClass: "upload-demo",
                          attrs: {
                            disabled:
                              _vm.dataForm.actStatus != "PREPARE"
                                ? true
                                : false,
                            "before-remove": _vm.beforeRemove,
                            "on-remove": _vm.handleRemove,
                            "on-preview": _vm.handlePreview,
                            "file-list": _vm.xgzlFileList,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.activeName == "taskList"
                  ? _c(
                      "div",
                      [
                        _c("grid-layout", {
                          ref: "gridLayOut2",
                          attrs: {
                            "table-options": _vm.hdchecktaskproOption,
                            "table-data": _vm.hdchecktaskproData,
                            "table-loading": _vm.tableLoading,
                          },
                          on: {
                            "grid-row-detail-click": _vm.rowView,
                            "gird-handle-select-click": _vm.selectionChange,
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm.formType == "view"
                                ? {
                                    key: "customBtn",
                                    fn: function ({ row }) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { margin: "0 3px" },
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.rowView(row)
                                              },
                                            },
                                          },
                                          [_vm._v("查看")]
                                        ),
                                      ]
                                    },
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.activeName == "Rectify"
                  ? _c("el-upload", {
                      staticClass: "upload-demo",
                      attrs: {
                        disabled: "",
                        "on-preview": _vm.handlePreview1,
                        "file-list": _vm.RectifyList,
                      },
                    })
                  : _vm._e(),
                _vm.activeName == "punishment"
                  ? _c("el-upload", {
                      staticClass: "upload-demo",
                      attrs: {
                        disabled: "",
                        "on-preview": _vm.handlePreview1,
                        "file-list": _vm.punishmentList,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("el-upload", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hideUpload,
            expression: "hideUpload",
          },
        ],
        ref: "uploadRef",
        staticClass: "upload-demo",
        attrs: {
          accept: ".doc,.docx,.pdf,.xlsx,.xls,.zip,.jpg,.png,.rar,.ppt,.pptx",
          action:
            "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
          "show-file-list": false,
          "on-success": _vm.handleSuccess,
          headers: _vm.headers,
        },
      }),
      _vm.deptShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "人员选择",
                visible: _vm.deptShow,
                width: "80%",
                top: "8vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.deptShow = $event
                },
              },
            },
            [
              _vm.deptShow
                ? _c("ProjectSelectUser", {
                    attrs: {
                      treeParams: { parentId: _vm.dataForm.organizationId },
                    },
                    on: {
                      closeDia: function ($event) {
                        _vm.deptShow = false
                      },
                      "select-data": _vm.getUser,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.MultipShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "人员选择",
                visible: _vm.MultipShow,
                width: "80%",
                top: "8vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.MultipShow = $event
                },
              },
            },
            [
              _vm.MultipShow
                ? _c("ProjectSelectUser", {
                    attrs: {
                      treeParams: { parentId: _vm.dataForm.organizationId },
                      multiple: "",
                    },
                    on: {
                      closeDia: function ($event) {
                        _vm.MultipShow = false
                      },
                      "select-data": _vm.getUser,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "项目选择", visible: _vm.deptShow1, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow1 = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            attrs: { deptCategory: [5], isOnlyShowPrj: true },
            on: { "select-data": _vm.selectData1 },
          }),
        ],
        1
      ),
      _vm.excelBox
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "检查分类导入",
                "append-to-body": "",
                visible: _vm.excelBox,
                width: "555px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.excelBox = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "import" },
                [
                  _c(
                    "avue-form",
                    {
                      attrs: {
                        option: _vm.excelOption,
                        "upload-after": _vm.uploadAfter,
                      },
                      model: {
                        value: _vm.excelOption.excelForm,
                        callback: function ($$v) {
                          _vm.$set(_vm.excelOption, "excelForm", $$v)
                        },
                        expression: "excelOption.excelForm",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "excelTemplate" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleTemplate },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("cip.plat.sys.user.field.download")
                                  )
                              ),
                              _c("i", {
                                staticClass: "el-icon-download el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.jcxModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: { title: "检查项", visible: _vm.jcxModel, width: "40%" },
              on: {
                "update:visible": function ($event) {
                  _vm.jcxModel = $event
                },
              },
            },
            [
              _vm.jcxModel
                ? _c("avue-form", {
                    attrs: { option: _vm.jcxOption },
                    on: {
                      "reset-change": _vm.resetChange1,
                      submit: _vm.handleSubmit1,
                    },
                    model: {
                      value: _vm.jcxForm,
                      callback: function ($$v) {
                        _vm.jcxForm = $$v
                      },
                      expression: "jcxForm",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.jcxflModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: "检查对象及分类新增",
                visible: _vm.jcxflModel,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.jcxflModel = $event
                },
              },
            },
            [
              _c("avue-form", {
                attrs: { option: _vm.jcxflOption },
                on: {
                  "reset-change": _vm.resetChange,
                  submit: _vm.handleSubmit,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "typeName",
                      fn: function () {
                        return [
                          _vm.jcxflForm.objectType == "SD" ||
                          _vm.jcxflForm.id ||
                          _vm.jcxflForm.isObject == 0
                            ? _c("el-input", {
                                model: {
                                  value: _vm.jcxflForm.typeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.jcxflForm, "typeName", $$v)
                                  },
                                  expression: "jcxflForm.typeName",
                                },
                              })
                            : _vm._e(),
                          _vm.jcxflForm.objectType == "EQ" &&
                          !_vm.jcxflForm.id &&
                          _vm.jcxflForm.isObject == 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleTable("EQ")
                                    },
                                  },
                                },
                                [_vm._v("选择设备设施")]
                              )
                            : _vm._e(),
                          _vm.jcxflForm.objectType == "AREA" &&
                          !_vm.jcxflForm.id &&
                          _vm.jcxflForm.isObject == 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleTable("qy")
                                    },
                                  },
                                },
                                [_vm._v("选择区域")]
                              )
                            : _vm._e(),
                          _vm.jcxflForm.objectType == "WORK" &&
                          !_vm.jcxflForm.id &&
                          _vm.jcxflForm.isObject == 1
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleTable("ts")
                                    },
                                  },
                                },
                                [_vm._v("选择特殊作业")]
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3935969581
                ),
                model: {
                  value: _vm.jcxflForm,
                  callback: function ($$v) {
                    _vm.jcxflForm = $$v
                  },
                  expression: "jcxflForm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.fxyModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "dangerBox",
              attrs: {
                title: "风险库及措施",
                visible: _vm.fxyModel,
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.fxyModel = $event
                },
              },
            },
            [
              _c(
                "el-container",
                { staticStyle: { height: "500px" } },
                [
                  _c("CommonTree", {
                    ref: "CommonTree",
                    staticStyle: { "font-size": "13px" },
                    attrs: {
                      treeTitle: "风险源",
                      defaultProps: {
                        children: "children",
                        id: "id",
                        label: "sourceTypeName",
                      },
                      searchTitle: "sourceTypeName",
                      showCheckbox: false,
                      treeData: _vm.treeData,
                      treeExpand: true,
                    },
                    on: { getNodeClick: _vm.handleNodeClick },
                  }),
                  _c(
                    "el-main",
                    [
                      _c("div", { staticClass: "searchTopBox" }, [
                        _c("div", { staticClass: "leftSearch" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "margin-bottom": "6px",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "searchBox",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入风险名称",
                                },
                                model: {
                                  value: _vm.searchForm.riskName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "riskName", $$v)
                                  },
                                  expression: "searchForm.riskName",
                                },
                              }),
                              _c("el-input", {
                                staticClass: "searchBox",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入措施分类",
                                },
                                model: {
                                  value: _vm.searchForm.treatmentTypeName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "treatmentTypeName",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.treatmentTypeName",
                                },
                              }),
                              _c("el-input", {
                                staticClass: "searchBox",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入措施描述",
                                },
                                model: {
                                  value: _vm.searchForm.treatmentDesc,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "treatmentDesc",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.treatmentDesc",
                                },
                              }),
                              _c("el-input", {
                                staticClass: "searchBox",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入隐患排查内容",
                                },
                                model: {
                                  value: _vm.searchForm.hdCheckContext,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "hdCheckContext",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.hdCheckContext",
                                },
                              }),
                              _c(
                                "el-select",
                                {
                                  staticClass: "searchBox",
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入管控层级",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.searchForm.controlLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "controlLevel",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.controlLevel",
                                  },
                                },
                                _vm._l(_vm.riskControlLevel, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c("el-input", {
                                staticClass: "searchBox",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入负责人员",
                                },
                                model: {
                                  value: _vm.searchForm.respUserName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "respUserName",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.respUserName",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "rightSearch" },
                                [
                                  _c(
                                    "el-button-group",
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                          icon: "el-icon-search",
                                        },
                                        on: { click: _vm.searchChange },
                                      }),
                                      _c("el-button", {
                                        staticStyle: { height: "28px" },
                                        attrs: {
                                          size: "mini",
                                          icon: "reset-refresh icon-refresh",
                                        },
                                        on: { click: _vm.searchReset },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("grid-layout", {
                        ref: "gridLayOutfxy",
                        attrs: {
                          "table-options": _vm.fxyOption,
                          "data-total": _vm.tableTotal,
                          "table-data": _vm.fxyTableData,
                          "table-loading": _vm.fxyTableLoading,
                          page: _vm.fxyPage,
                        },
                        on: {
                          "gird-handle-select-click": _vm.selectionChange,
                          "page-size-change": _vm.handleSizeChange,
                          "page-current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "avue-dialog__footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.fxyModel = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleFxySave },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("fileView", { ref: "fileView", attrs: { title: "附件预览" } }),
      _vm.zxhcModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "avue-dialog avue-dialog--top",
              attrs: {
                title: "检查项查看",
                visible: _vm.zxhcModel,
                width: "60%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.zxhcModel = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "modelFormDataForm",
                  attrs: {
                    model: _vm.modelForm,
                    "label-width": "150px",
                    disabled: _vm.modelForm.disabled,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "检查结果", prop: "lineStatus" },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "NORMAL" },
                                  model: {
                                    value: _vm.modelForm.lineStatus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.modelForm, "lineStatus", $$v)
                                    },
                                    expression: "modelForm.lineStatus",
                                  },
                                },
                                [_vm._v("正常")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: "ERROR" },
                                  model: {
                                    value: _vm.modelForm.lineStatus,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.modelForm, "lineStatus", $$v)
                                    },
                                    expression: "modelForm.lineStatus",
                                  },
                                },
                                [_vm._v("异常")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检查地点", prop: "prjName" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: { placeholder: "请填写检查地点" },
                                  model: {
                                    value: _vm.modelForm.position,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.modelForm, "position", $$v)
                                    },
                                    expression: "modelForm.position",
                                  },
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _c("div", {
                                      staticClass: "el-icon-map-location",
                                      staticStyle: {
                                        width: "54px",
                                        "text-align": "center",
                                      },
                                    }),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "检查内容", prop: "indexName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  maxlength: "500",
                                  "show-word-limit": "",
                                  placeholder: "请输入检查内容",
                                },
                                model: {
                                  value: _vm.modelForm.indexName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modelForm, "indexName", $$v)
                                  },
                                  expression: "modelForm.indexName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "隐患编号", prop: "hdCode" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "隐患编号自动生成",
                                },
                                model: {
                                  value: _vm.modelForm.hdCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modelForm, "hdCode", $$v)
                                  },
                                  expression: "modelForm.hdCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "隐患描述", prop: "hdDesc" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 2 },
                                  "show-word-limit": "",
                                  maxlength: "225",
                                  placeholder: "请填写隐患描述",
                                },
                                model: {
                                  value: _vm.modelForm.hdDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.modelForm, "hdDesc", $$v)
                                  },
                                  expression: "modelForm.hdDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "隐患情况", prop: "prjAs" } },
                            [
                              _c(
                                "div",
                                { staticClass: "content" },
                                _vm._l(
                                  _vm.ModelTreeData,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: item.id,
                                        staticClass: "img-content",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlePreview(item)
                                          },
                                        },
                                      },
                                      [
                                        item.extension == "jpg" ||
                                        item.extension == "png" ||
                                        item.extension == "image/jpeg" ||
                                        item.extension == "image/png"
                                          ? _c("img", {
                                              attrs: {
                                                src: item.link,
                                                alt: "",
                                              },
                                            })
                                          : _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/dashboard-nodata.png"),
                                                alt: "",
                                              },
                                            }),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "avue-dialog__footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.zxhcModel = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("selectProjectDialogVue", {
        ref: "selectProjectDialog",
        on: { changeProject: _vm.changeProject },
      }),
      _vm.WorkTicketShow
        ? _c("WorkTicket", {
            ref: "WorkTicket",
            attrs: { prjId: _vm.dataForm.organizationId },
            on: { selectWorkTicket: _vm.selectWorkTicket },
          })
        : _vm._e(),
      _c("riskArea", {
        ref: "riskArea",
        attrs: { multiple: "" },
        on: { callback: _vm.selectWorkTicket },
      }),
      _c("equipmentDialog", {
        ref: "equipmentDialog",
        on: { callback: _vm.selectWorkTicket },
      }),
      _vm.showKnowledgeSearchDialog
        ? _c(
            "el-dialog",
            {
              attrs: {
                top: "9vh",
                title: "知识库选择",
                visible: _vm.showKnowledgeSearchDialog,
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showKnowledgeSearchDialog = $event
                },
              },
            },
            [
              _c("KnowledgeSearchDialog", {
                ref: "knowledgeSearchDialog",
                on: {
                  "close-dialog": _vm.closeKnowledgeSearchDialog,
                  "select-data": _vm.selectFile,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }